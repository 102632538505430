<template>
  <b-modal id="message-modal" :no-close-on-backdrop="true" @hidden="hidden(isSendEmail)">
    <div class="box-item">
      <div class="header">
        <img src="@/assets/image/market/message-log.png" alt="">
      </div>
      <div class="body">
        <div>
          <label for="from" class="control-label">From</label>
          <input v-model="ListQuery.From" type="text" class="form-control" id="from" disabled>
        </div>
        <div>
          <label for="to" class="control-label">To</label>
          <input v-model="ListQuery.To" type="text" class="form-control" id="to" disabled>
        </div>
      </div>
      <div class="footer">
        <div>
          <label class="control-label">Message</label>
          <b-form-textarea style="width: 765px;min-height: 240px" :placeholder="ContentPlaceholder" v-model="ListQuery.Content" class="form-control" id="Content"
                           @blur="checkReq" :class="{ error : Content ,isEnExpire : ContentPlaceholder === 'Sorry, this category is required to Membership Access.'}"/>
        </div>
      </div>
      <div class="button hand" @click="handleSend">
        <img src="@/assets/image/market/send.png" alt="">
        <div>Send</div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getLocalStore } from '@/utils/common'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ListQuery: {
        From: '',
        To: '',
        Content: ''
      },
      postEmail: {},
      isSendEmail: false,
      Content: ''
    }
  },
  watch: {
    'data.To' (nval, oval) {
      if (nval !== oval) {
        this.ListQuery.From = this.data.From
        this.ListQuery.To = this.data.To
        this.Content = ''
        this.ListQuery.Content = ''
        this.postEmail = {}
      }
    }
  },
  computed: {
    ContentPlaceholder () {
      return this.isEnExpire ? '*Your message will be sent to the BTC Account of the Chinese buyers' : 'Sorry, this category is required to Membership Access.'
    }
  },
  methods: {
    handleSend () {
      if (this.checkReq()) {
        if (this.isEnExpire) {
          this.postEmail.Sender = this.data.FromContactPersonName
          this.postEmail.SenderUserID = getLocalStore().UserID
          this.postEmail.Receiver = this.data.ToContactPersonName
          this.postEmail.ReceiverUserID = this.data.ToLoginUserName
          this.postEmail.Content = this.ListQuery.Content
          this.postEmail.AuditState = 1
          this.$store.dispatch('SendFuturesEmail', this.postEmail).then(() => {
            this.ListQuery.Content = ''
            this.isSendEmail = true
            this.$bvModal.hide('message-modal')
          })
        } else {
          this.openMembershipModal()
        }
      }
    },
    checkReq () {
      if (!this.ListQuery.Content || !this.ListQuery.Content.replace(/\s*/g, '')) {
        this.Content = 'Please enter content'
        return false
      } else {
        this.Content = ''
        return true
      }
    },
    hidden (isSend) {
      if (isSend) {
        this.isSendEmail = false
        this.$emit('hidden')
      }
    }
  }
}
</script>

<style>
#message-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1026px;
  height: 90vh;
}

#message-modal .modal-content {
  background-color: #fff0;
  border: none;
}

#message-modal .modal-header {
  background-color: #fff0;
  border: none;
  margin-bottom: -5px;
  z-index: 999;
}

#message-modal .modal-body {
  background-color: #ffffff;
  border-radius: 20px;
}

#message-modal .modal-footer {
  display: none;
}

#message-modal .modal-content .close {
  color: #ffffff;
  font-size: 40px;
}

</style>

<style scoped>

label{
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.box-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header{
  width: 100%;
  text-align: center;
}

.header > img{
  width: 380px;
  height: 76px;
  margin: 10px 0;
}

.body{
  width: 765px;
  display: flex;
  justify-content: space-between;
}

.form-control{
  background-color: #F5F5F5!important;
  color: #014379;
  font-weight: lighter;
  font-size: 16px;
  width: 357px;
  height: 58px;
  border: none;
}

.button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 109px;
  height: 36px;
  font-size: 17px;
  font-weight: bold;
  padding: 5px 20px 5px 10px;
  margin: 20px 0 10px 0;
  background-color: #4285F4;
  color: #FFFFFF;
  border-radius: 5px;
}

.button > img{
  width: 25px;
  height: 21px;
}
.isEnExpire::placeholder {
  color: red;
}
</style>
