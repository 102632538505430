<template>
  <div class="container-fluid">
    <div class="market-head">
      <div>
        <img style="margin-bottom: 5px;width: 388px;height: 176px" src="@/assets/image/market/logo.png">
        <div>
          <input v-model="listQuery.KeyWords" type="text" @keyup.enter="handleInput(false)">
          <div class="search hand" @click="handleInput">Search</div>
        </div>
      </div>
    </div>
    <div v-show="!allShow" class="market-company hand" @click="allShow=!allShow">
      <img src="@/assets/image/market/companyEn/beef.png" alt="" style="width: 110px;height: 55px;margin-right: 45px" title="Partially listed.">
      <div v-for="(item, index) in companyEn" :key="index">
        <img  :src="item" alt="">
      </div>
    </div>
    <div v-show="!allShow" class="market-company" style="padding: 10px 0 0 0;background-color: #f5f5f5;font-size: 14px">
      <span v-if="$store.getters.token && isEnExpire" style="width: 1160px">
        The access of BTC Market is freely released to Regular Membership before the BTC Market is fully constructed.
        <img class="hand" @click="handleMarketMemberships" src="@/assets/image/login/query.png" alt="" style="width: 16px;height: 16px;margin-top: -4px">
      </span>
    </div>
    <div v-show="allShow" class="market-company-all hand" @click="allShow=!allShow">
      <img src="@/assets/image/market/companyEn/beef.png" alt="" style="width: 110px;height: 55px;margin-right: 45px;margin-top: 20px; margin-left: 95px" title="Partially listed.">
      <div>
        <div v-for="(item, index) in companyEnAll" :key="index">
          <img :src="item" alt="">
        </div>
      </div>
    </div>
    <div class="market-table">
      <div v-for="(item,index) in list" :key="index">
        <img :src="item.icon" alt="" style="height: 141px">
        <div class="market-table-body">
          <div class="">{{ item.CompanyNameEn }}</div>
          <div style="margin-bottom: 12px">{{ item.CompanyName }}</div>
          <div class="table-body-group" style="margin-top: 10px">
            <div>
              <span>Year Founded :</span>
              <span>{{ item.Founded }}</span>
            </div>
            <div>
              <span>Role in Beef Industry :</span>
              <span>{{ item.Role }}</span>
            </div>
          </div>
          <div class="table-body-group">
            <div>
              <span>Province / City :</span>
              <span>{{ item.City }}</span>
            </div>
            <div>
              <span>Import Qualification :</span>
              <span>{{ item.Import ? 'Yes' : 'No' }}</span>
            </div>
          </div>
        </div>
        <div class="market-table-img">
<!--          <img class="hand" :src="item.type" alt="" @click="handleType(item.Type)">-->
          <img class="hand" src="@/assets/image/market/no-message.png" alt="" @click="handleMessage(item)">
        </div>
      </div>
      <div v-if="total !== list.length" class="more hand" @click="handleMore">
        <img style="width: 38px;height: 32px" src="@/assets/image/market/up.png" title="Full list is required to Membership Access." alt="">
      </div>
      <div v-if="list.length === 0"
           style="width: 1200px; min-height: 600px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 18px;">
        <img src="@/assets/image/est/no-msg.png">
      </div>
    </div>
    <message-dialog :data="messageData" :is-en-expire="isEnExpire" @hidden="hidden"/>
    <common-dialog id="sendEmailDialog" :message="email"/>
  </div>
</template>

<script>

import commonmix from '@/utils/commonmix'
import MessageDialog from '@/components/MessageDialog'
import CommonDialog from '@/components/CommonDialog'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'Market',
  components: {
    MessageDialog,
    CommonDialog
  },
  computed: {
    isEnExpire () {
      return this.getUserProfile(this.$t('message._beefmarket_en'))
    }
  },
  data () {
    return {
      email: 'sendEmail',
      allShow: false,
      listQuery: {
        KeyWords: '',
        Type: '',
        PageNum: 1,
        PageSize: 9
      },
      companyEn: [
        require('@/assets/image/market/companyEn/OverseaMember01.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember02.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember05.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember06.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember07.jpg')
      ],
      companyEnAll: [
        require('@/assets/image/market/companyEn/OverseaMember01.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember02.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember05.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember06.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember07.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember08.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember09.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember10.png'),
        require('@/assets/image/market/companyEn/OverseaMember11.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember12.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember13.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember14.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember16.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember17.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember19.png'),
        require('@/assets/image/market/companyEn/OverseaMember20.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember21.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember23.png'),
        require('@/assets/image/market/companyEn/OverseaMember24.png'),
        require('@/assets/image/market/companyEn/OverseaMember25.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember27.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember28.png'),
        require('@/assets/image/market/companyEn/OverseaMember29.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember30.png'),
        require('@/assets/image/market/companyEn/OverseaMember31.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember32.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember33.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember34.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember35.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember36.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember37.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember38.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember39.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember40.png'),
        require('@/assets/image/market/companyEn/OverseaMember41.png'),
        require('@/assets/image/market/companyEn/OverseaMember42.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember43.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember44.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember45.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember46.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember47.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember48.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember49.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember50.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember51.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember52.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember53.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember54.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember55.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember56.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember57.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember58.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember59.jpg'),
        require('@/assets/image/market/companyEn/OverseaMember60.png'),
        require('@/assets/image/market/companyEn/OverseaMember61.png'),
        require('@/assets/image/market/companyEn/OverseaMember62.png'),
        require('@/assets/image/market/companyEn/OverseaMember63.png'),
        require('@/assets/image/market/companyEn/OverseaMember64.png'),
        require('@/assets/image/market/companyEn/OverseaMember65.png'),
        require('@/assets/image/market/companyEn/OverseaMember66.png'),
        require('@/assets/image/market/companyEn/OverseaMember67.png'),
        require('@/assets/image/market/companyEn/OverseaMember68.png'),
        require('@/assets/image/market/companyEn/OverseaMember69.png'),
        require('@/assets/image/market/companyEn/OverseaMember70.png'),
        require('@/assets/image/market/companyEn/OverseaMember71.png')
      ],
      list: [],
      current: 0,
      total: 0,
      dict: {},
      messageData: {
        From: '',
        To: ''
      }
    }
  },
  mounted () {
    setInterval(this.getCompanyEn, 3600)
    this.getMarketList(false)
    const _self = this
    if (document.getElementsByClassName('market-table')[0]) {
      document.getElementsByClassName('market-table')[0].addEventListener('scroll', function () {
        if (this.scrollHeight - Math.ceil(this.scrollTop) === this.clientHeight) {
          if (_self.$store.getters.token) {
            if (_self.isEnExpire) {
              _self.getMarketList(true)
            }
          }
        }
      })
    }
  },
  methods: {
    getMarketList (isMore) {
      if (isMore) {
        this.listQuery.PageNum += 1
      } else {
        this.listQuery.PageNum = 1
      }
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebMarket', this.listQuery).then(() => {
        if (isMore) {
          this.list = this.list.concat(this.$store.getters.marketRows)
        } else {
          this.list = this.$store.getters.marketRows
        }
        this.total = this.$store.getters.marketTotal
        this.getIcon()
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.log(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getFrom (item) {
      this.$store.dispatch('GetWebFrom', {
        UserID: getLocalStore().UserID
      }).then(() => {
        this.dict = this.$store.getters.marketDict
        this.messageData.From = this.dict.Company
        this.messageData.To = item.CompanyNameEn
        this.messageData.UserName = this.dict.UserName
        this.messageData.ToLoginUserName = item.LoginUserName
        this.messageData.ToContactPersonName = item.ContactPersonName
        this.messageData.FromLoginUserName = this.dict.LoginUserName
        this.messageData.FromContactPersonName = this.dict.UserName
        this.$bvModal.show('message-modal')
      }).catch((err) => {
        console.log(err)
      })
    },
    handleMore () {
      if (this.$store.getters.token) {
        if (this.isEnExpire) {
          this.getMarketList(true)
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    handleInput () {
      if (this.$store.getters.token) {
        if (this.isEnExpire) {
          this.listQuery.Type = ''
          this.getMarketList(false)
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    handleType (type) {
      if (this.$store.getters.token) {
        if (this.isEnExpire) {
          this.listQuery.Type = type
          this.getMarketList(false)
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    getCompanyEn () {
      const all = this.companyEnAll.length
      if (all - this.current >= 5) {
        const allTest = this.companyEnAll.slice(0)
        this.companyEn = allTest.splice(this.current, 5)
        this.current = this.current + 5
      } else {
        this.current = 0
        this.getCompanyEn()
      }
    },
    getIcon () {
      this.list.forEach(item => {
        if (item && item.CompanyNameEn) {
          item.icon = require(`@/assets/image/market/company/${item.Picture}.png`)
        }
        return null
      })
    },
    handleMessage (item) {
      if (this.$store.getters.token) {
        if (JSON.stringify(this.dict) === '{}') {
          this.getFrom(item)
        } else {
          this.messageData.From = this.dict.Company
          this.messageData.To = item.CompanyNameEn
          this.messageData.UserName = this.dict.UserName
          this.messageData.ToLoginUserName = item.LoginUserName
          this.messageData.ToContactPersonName = item.ContactPersonName
          this.messageData.FromLoginUserName = this.dict.LoginUserName
          this.messageData.FromContactPersonName = this.dict.UserName
          this.$bvModal.show('message-modal')
        }
      } else {
        this.openLoginModal()
      }
    },
    hidden () {
      this.$bvModal.show('sendEmailDialog')
    },
    handleMarketMemberships () {
      if (this.$store.getters.token) {
        this.openMembershipModal()
      }
    }
  }
}
</script>

<style scoped>
.container-fluid {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.market-head {
  height: 280px;
  position: relative;
  background: #FFFFFF;
  border-bottom: 1px solid #E6E6E6;
}

.market-head > div {
  position: absolute;
  right: 0;
  bottom: 35px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.market-head > div > div {
  width: 517px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 4px;
}

.market-head > div > div > input {
  width: 480px;
  height: 35px;
  border: 1px solid #CCCCCC;
  border-radius: 9px 0 0 9px;
  padding: 10px;
  outline: none;
}

.search {
  width: 62px;
  height: 35px;
  border-radius: 0 9px 9px 0;
  background: #006EB8;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.market-company {
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.market-company > div{
  border-left: 1px solid #E6E6E6;
}

.market-company > div > img {
  margin: 0 45px;
  width: 110px;
  height: 55px;
}

.market-company > img:last-child {
  border-right: 1px solid #E6E6E6;
}

.market-company-all {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px #e0e0e0;
  z-index: 2;
}

.market-company-all > div {
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.market-company-all > div > div{
  border-left: 1px solid #E6E6E6;
  margin: 20px 0;
}

.market-company-all > div > div > img {
  margin: 10px 45px 0 45px;
  width: 110px;
  height: 55px;
}

.market-table {
  max-height: 1060px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  overflow-y: scroll;
}

.market-table > div {
  width: 1154px;
  height: 141px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 20px 50px 20px 60px;
}

.market-table > .more {
  width: 140px;
  height: 50px;
  border-radius: 25px;
  background-color: #f5f5f5;
  border: #f5f5f5;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  padding: 20px;

  animation:mymove 1.5s infinite;
  -webkit-animation:mymove 1.5s infinite;
}

@keyframes mymove{
  0% {transform: translate(0px, 0px);}
  50% {transform: translate(0px, -10px);}
  100% {transform: translate(0px, 0px);}

}

/*Safari 和 Chrome:*/
@-webkit-keyframes mymove
{
  0% {transform: translate(0px, 0px);}
  50% {transform: translate(0px, -10px);}
  100% {transform: translate(0px, 0px);}
}

.market-table-body {
  color: #333333;
}

.market-table-body > div {
  margin: 4px;
}

.market-table-body > div:first-child {
  max-width: 580px;
  font-size: 16px;
  font-weight: bold;
}

.market-table-body > div:nth-child(2) {
  font-size: 14px;
}

.table-body-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #666666;
  font-size: 14px;
}

.table-body-group {
  margin: 1px 4px!important;
}

.table-body-group > div:first-child {
  width: 260px;
}

.table-body-group > div:first-child > span:first-child {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 110px;
}

.table-body-group > div:last-child {
  width: 320px;
}

.table-body-group > div:last-child > span:first-child {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 150px;
}

.market-table-img {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.market-table-img > img:first-child {
  height: 30px;
  width: 30px;
}

.market-table-img > img:last-child {
  width: 39px;
  height: 36px;
}

.more {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0 40px;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #e0e0e0;
  margin: 10px 0;
  margin-top: 20px;
}

</style>
